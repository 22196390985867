// App.js

import './App.css';
import SpeedTypingGame from './components/SpeedTypingGame';

function App() {
return (
	<div className="App">
	<SpeedTypingGame />
	</div>
);
}

export default App;
